<template>
  <div v-if="typeHasMeta">
    <v-expansion-panels v-if="slotSpecificFields.length > 0">
      <v-expansion-panel v-for="item in widget.slots" :key="item.slotId">
        <v-expansion-panel-header class="ep-style">
          <b>
            {{ slotName(item.index, item.key) }}
          </b>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="(k, i) in slotSpecificFields" :key="i" class="d-flex">
            <widget-meta-field
              v-model="widget"
              :slot-id="item.slotId"
              :m-key="k.key"
              :name="k.name"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div v-for="k in fields" :key="k.key" class="d-flex">
      <widget-meta-field v-model="widget" :m-key="k.key" :name="k.name" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import widgetMixin from "../../../_helpers/widgetMixin";
import WidgetMetaField from "./WidgetMetaField.vue";

export default {
  name: "WidgetMetaForm",
  components: { WidgetMetaField },
  props: {
    mdlWidget: {
      default: {},
      require: true,
    },
  },

  model: { prop: "mdlWidget" },
  mixins: [widgetMixin],

  data() {
    return {};
  },

  methods: {
    isPsm(key) {
      var inpField = this.moduleMeta.InputFields[key];
      return inpField?.psm !== undefined || inpField?.psm === true;
    },

    slotName(index, key) {
      return this.getSlotName(index, key);
    },
  },

  computed: {
    ...mapGetters("slots", ["getSlotName"]),

    slotSpecificFields() {
      return this.moduleMeta.Keys.filter(
        (d) =>
          this.moduleMeta.Widgets[this.widget.widgetType].includes(d.key) &&
          this.isPsm(d.key)
      );
    },

    fields() {
      if (this.widget == undefined) return [];

      // WARNING: This is a cluster of questions regarding the soring
      //
      // Gets the meta keys for the widget type we then ignore fields that sets the deveui because this
      // is handled by slots. After that we sort so the boolean values comes first to make the form more uniform
      // Also make sure if the chart type is in the list that it is on top
      return this.moduleMeta.Keys.filter(
        (d) =>
          this.moduleMeta.Widgets[this.widget.widgetType].includes(d.key) &&
          !this.isPsm(d.key)
      )
        .filter((d) => !this.ignoreMetaKeys.includes(d.key))
        .sort((a, b) => {
          // Sort Chart Type at top always
          if (this.isType(this.metaKeys.CHART_TYPE, a.key)) return 0;
          else if (this.isType(this.metaKeys.CHART_TYPE, b.key)) return -1;
          if (this.isType(this.metaKeys.DEFAULT_TIMESPAN, a.key)) return 0;
          else if (this.isType(this.metaKeys.DEFAULT_TIMESPAN, b.key))
            return -1;
          else if (this.isMetaBool(a.key) && !this.isMetaBool(b.key)) return 0;
          else if (this.isMetaBool(a.key) && this.isMetaBool(b.key)) return 0;
          else if (this.isMetaBool(b.key) && !this.isMetaBool(a.key)) return -1;

          return 0;
        })
        .reverse();
    },

    typeHasMeta() {
      if (
        this.mdlWidget?.widgetType == undefined ||
        this.moduleMeta.Widgets[this.mdlWidget.widgetType] == undefined ||
        this.moduleMeta.Widgets[this.mdlWidget.widgetType].length <= 0
      )
        return false;

      return true;
    },

    calledScripts() {
      return this.scripts.filter((s) => {
        return s.type == 1;
      });
    },

    widget: {
      get() {
        return this.mdlWidget;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.ep-style {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>