<template>
  <div class="d-flex flex-wrap justify-start align-start">
    <div
      v-for="tp in types"
      :key="tp.value"
      @click="() => (localType = tp.value)"
      :class="`d-flex jusitfy-start align-center widget-type-container ${
        localType === tp.value ? 'selected-type' : ''
      }`"
    >
      <v-icon
        class="pa-2"
        large
        :color="localType === tp.value ? 'primary' : ''"
        >{{ tp.icon }}</v-icon
      >
      <div
        class="d-flex flex-column justify-center align-start overflow-hidden"
        style="width: 100%; height: 100%"
      >
        <span class="overline title-type-text">{{ tp.name }}</span>
        <span class="caption">{{ tp.desc }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleTypeHelper from "../../_helpers/ModuleTypeHelper.js";
import widgetMixin from "../../_helpers/widgetMixin.js";

export default {
  name: "WidgetTypeSelector",

  props: {
    mdl: Number,
  },

  model: { prop: "mdl" },

  mixins: [widgetMixin],

  data() {
    return {};
  },

  computed: {
    types() {
      return ModuleTypeHelper.types.filter(
        (d) => !this.ignoreWidgetTypes.includes(d.value)
      );
    },

    localType: {
      get() {
        return this.mdl;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
.widget-type-container {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0.1em;
  width: -webkit-calc((100% / 2) - 0.6em);
  width: -moz-calc((100% / 2) - 0.6em);
  width: calc((100% / 2) - 0.6em);
  border-radius: 5px;

  height: 75px;
  cursor: pointer;
}

.selected-type {
  border: 2px solid #2196f3;
}

.title-type-text {
  line-height: 0.5rem !important;
  font-weight: bolder;
}
</style>