<template>
  <div>
    <h3>{{ $t("module.meta.fields.chart_type") }}</h3>
    <div>
      <v-btn
        v-for="t in chartTypes"
        :key="t.value"
        :color="chartType === t.value ? 'primary' : ''"
        :outlined="chartType === t.value"
        small
        @click="() => (chartType = t.value)"
        class="ma-2"
      >
        <v-icon small>{{ t.icon }}</v-icon>
        <span class="pa-2">{{ t.name }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { chartMixin } from "../../../_helpers/chartMixin";
export default {
  name: "ChartTypeSelector",

  props: {
    value: {
      default: String,
      require: true,
    },
  },

  model: {
    prop: "value",
  },

  mixins: [chartMixin],

  data() {
    return {};
  },

  computed: {
    chartType: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>