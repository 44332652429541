<template>
  <v-main style="width: 100%; max-height: 100%; height: 100%">
    <div class="d-flex flex-column justify-start align-start pa-2 fill-height">
      <div class="pa-2" style="width: 100%">
        <div class="d-flex justify-space-between align-center">
          <div>
            <v-text-field
              prepend-inner-icon="mdi-pen"
              v-model="name"
              outlined
            ></v-text-field>
          </div>

          <v-btn @click="saveTemplateDashboard" class="ml-2" color="primary">{{
            $t("templateDashboard.saveDashboard")
          }}</v-btn>
        </div>
      </div>

      <div class="d-flex justify-center align-center" style="width: 100%">
        <v-btn
          class="ma-2"
          :color="type == t.value ? 'primary' : ''"
          :outlined="type != t.value"
          x-small
          v-for="t in types.filter((e) =>
            allowedDashboardTypes.includes(e.value)
          )"
          :key="t.value"
          @click="() => (type = t.value)"
        >
          <v-icon small>{{ t.icon }}</v-icon>
          <span class="pl-3">{{ t.name }}</span>
        </v-btn>
      </div>

      <v-divider class="mt-2" style="width: 100%" />

      <div class="grow ma-0 pa-0" style="width: 100%">
        <template-widgets-form
          ref="templateWidgetForm"
          @add-or-update-widget="saveOrUpdateWidget"
          :dashboard-type="type"
        />
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TemplateWidgetsForm from "./TemplateWidgetsForm.vue";

export default {
  components: { TemplateWidgetsForm },
  name: "TemplateDashboardForm",

  data() {
    return {
      name: this.$t("templateDashboard.defaults.name"),
      type: 1,
      types: [
        {
          name: this.$t("templateDashboard.dashboardType.0"),
          value: 0,
          icon: "mdi-monitor-dashboard",
        },
        {
          name: this.$t("templateDashboard.dashboardType.1"),
          value: 1,
          icon: "mdi-monitor-dashboard",
        },
        {
          name: this.$t("templateDashboard.dashboardType.2"),
          value: 2,
          icon: "mdi-monitor-dashboard",
        },
      ],

      allowedDashboardTypes: [1],
    };
  },

  methods: {
    ...mapActions("templateDashboard", [
      "saveDashboardToState",
      "reset",
      "createTemplateDashboard",
      "updateTemplateDashboard",
      "saveTemplateDashboardWidgets",
      "setWidgets",
      "addOrUpdateWidget",
      "getTemplateDashboard",
      "getDashboardWidgets",
    ]),

    ...mapActions("slots", ["addSlot", "resetSlots"]),

    iconFocus() {
      this.$refs.name.focus();
    },

    saveOrUpdateWidget(widget) {
      // Set the cordinates and size
      if (widget.x === undefined) {
        let maxI = 0;
        if (this.widgets?.length !== undefined && this.widgets.length > 0)
          maxI = Math.max(...this.widgets?.map((d) => d.i));

        let widgetLength =
          this.widgets?.length === undefined || this.widgets.length === 0
            ? 0
            : isNaN(maxI)
            ? this.widgets?.length + 1
            : maxI + 1;

        widget = {
          ...widget,
          x: 0,
          y: widgetLength * 10,
          w: 24,
          h: 8,
          i: widgetLength,
        };
      }

      // Store dashboard to temporary store
      if (Object.keys(this.dashboard).length === 0) {
        this.saveDashboardToState({
          name: this.name,
          type: 1,
          widgets: [widget],
        });

        this.setWidgets([widget]);
        return;
      }

      // If widgets are undefined set them to empty array
      if (this.widgets === undefined) {
        this.setWidgets([]);
      }

      // If that is the case update the widget instead of pushing a new one
      this.addOrUpdateWidget(widget);
      this.saveDashboardToState(this.dashboard);

      // We most likely need a refresh of widgets here to make template dashboard react to the update
      this.$refs.templateWidgetForm.$refs.dashboard.refresh();
    },

    async saveTemplateDashboard() {
      if (this.dashboard === undefined) return;

      // Save the template dashboard and
      // then the widgets for that dashboard
      let dbPayload = {
        name: this.name,
        type: this.type,
      };
      if (this.templateDashboardId === undefined) {
        let dId = await this.createTemplateDashboard(dbPayload);
        if (dId !== undefined) {
          this.saveDashboardToState({
            templateDashboardId: dId,
            ...dbPayload,
            widgets: this.widgets ?? [],
          });
        }
      } else {
        await this.updateTemplateDashboard({
          templateDashboardId: this.templateDashboardId,
          payload: dbPayload,
        });
      }

      if (this.widgets !== undefined || this.widgets.length > 0) {
        await this.saveTemplateDashboardWidgets(this.widgets);
      }

      this.$router.push("/dashboard");
    },
  },

  computed: {
    ...mapState("templateDashboard", ["dashboard", "widgets"]),
    ...mapGetters("templateDashboard", ["templateDashboardId"]),
  },

  async created() {
    this.reset();
    this.resetSlots();

    // Seed slots widgets and the rest
    let id = this.$route.params.id;
    if (id !== undefined) {
      await this.getTemplateDashboard(id);
      await this.getDashboardWidgets(id);

      this.name = this.dashboard.name;
      this.type = this.dashboard.type;

      // Add slots
      for (var widget of this.widgets) {
        for (var slot of widget.slots) this.addSlot(slot);
      }
    }
  },
};
</script>
