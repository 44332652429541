<template>
  <v-container
    fill-height
    fluid
    class="d-flex justify-end align-start ma-0 pa-0 flex-sm-wrap flex-md-wrap flex-lg-nowrap"
  >
    <div class="of-scroll" style="width: 100%; max-width: 100%">
      <widget-form
        :input-widget="selectedWidget"
        @add-or-update-widget="
          (val) => {
            $emit('add-or-update-widget', val);
            selectedWidget = undefined;
          }
        "
      />
    </div>

    <div
      :style="dashboardContainerType(this.dashboardType)"
      style="height: 100%; max-height: 100% !important"
    >
      <template-dashboard
        ref="dashboard"
        @select-widget="(w) => (selectedWidget = w)"
      />
    </div>
  </v-container>
</template>

<script>
import WidgetForm from "./WidgetForm.vue";
import TemplateDashboard from "./TemplateDashboard.vue";
import { templateDashboardMixin } from "../../_helpers/templateDashboardMixin.js";

export default {
  name: "TemplateWidgetsForm",

  mixins: [templateDashboardMixin],

  components: {
    WidgetForm,
    TemplateDashboard,
  },

  props: {
    dashboardType: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      selectedWidget: undefined,
    };
  },

  computed: {},

  methods: {},

  created() {},
};
</script>

<style>
.of-scroll {
  overflow-y: scroll;
}
</style>