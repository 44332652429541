<template>
  <div class="d-flex flex-wrap justify-start align-start">
    <div
      class="d-flex justify-space-between align-center pa-2 slot-add-container"
    >
      <v-select
        :items="indexes"
        :label="$t('slot.slotIndex')"
        v-model="index"
        prepend-inner-icon="mdi-counter"
        style="max-width: 125px; margin-right: 0.5rem"
      ></v-select>

      <v-autocomplete
        :items="tagKeysMapped"
        item-text="labelAndKey"
        item-value="key"
        v-model="key"
        :label="$t('tagdata.fields.key')"
        :hint="$t('slot.addHint')"
        persistent-hint
        prepend-inner-icon="mdi-broadcast"
        :filter="customFilter"
      />
      <v-btn icon @click="addSlotWithKey" color="green"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
    </div>

    <!-- 
      Reset all slots
      <div class="slot-reset-container mt-2 mb-4">
      <v-btn @click="resetSlots" color="accent"
        ><v-icon>mdi-redo</v-icon> {{ $t("slot.resetSlots") }}</v-btn
      >
    </div> -->

    <div
      v-for="(tp, i) in storeSlots"
      :key="i"
      @click="addOrRemoveSlot(tp.index, tp.key)"
      :class="`d-flex jusitfy-start align-center widget-type-container ${
        isSelected(tp.index, tp.key) ? 'selected-type' : ''
      }`"
      style="position: relative"
    >
      <v-btn
        v-if="isSelected(tp.index, tp.key) && false"
        x-small
        absolute
        top
        right
        icon
        color="error"
        ><v-icon>mdi-delete</v-icon></v-btn
      >
      <v-icon
        class="pa-2"
        large
        :color="isSelected(tp.index, tp.key) ? 'primary' : ''"
        >mdi-form-select</v-icon
      >
      <div
        class="d-flex flex-column justify-center align-start overflow-hidden"
        style="width: 100%; height: 100%"
      >
        <span class="overline title-type-text">{{ tp.slot }}</span>
        <span class="caption">Key: {{ tp.key }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "SlotSelector",

  props: {
    slots: {
      default: () => [],
    },
  },

  model: { prop: "slots" },

  data() {
    return { key: "", index: 1, indexes: [1] };
  },

  methods: {
    ...mapActions("slots", ["addSlot", "resetSlots"]),
    ...mapActions("tag", ["getAllKeys"]),

    addOrRemoveSlot(index, key) {
      var sltIdx = this.localSlots.findIndex(
        (d) => d.key == key && d.index == index
      );
      if (sltIdx !== -1) {
        this.localSlots.splice(sltIdx, 1);
        return;
      }

      this.localSlots.push({
        ...this.getSlot(index, key),
        slotId: crypto.randomUUID(),
      });
    },

    addSlotWithKey() {
      if (this.key == undefined || this.key === "") return;

      this.addSlot({ index: this.index, key: this.key });

      const widgetOrDashboardTypeCanHaveMoreThanOneSlotIndex = false;
      if (widgetOrDashboardTypeCanHaveMoreThanOneSlotIndex == true)
        this.indexes.push(this.indexes.length + 1);
    },

    isSelected(index, key) {
      var sltIdx = this.localSlots.findIndex(
        (d) => d.key == key && d.index == index
      );
      return sltIdx !== -1;
    },

    customFilter(item, queryText, itemText) {
      let qLower = queryText.toLowerCase();
      return (
        (item.key !== undefined && item.key.toLowerCase().includes(qLower)) ||
        itemText.toLowerCase().includes(qLower)
      );
    },
  },

  computed: {
    ...mapState("slots", { storeSlots: "slots" }),
    ...mapState("tag", { tagKeys: "keys" }),
    ...mapGetters("slots", ["getSlot"]),

    tagKeysMapped() {
      return this.tagKeys.map((d) => {
        return {
          ...d,
          labelAndKey: d.label + " - " + d.key,
        };
      });
    },

    localSlots: {
      get() {
        return this.slots;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  async created() {
    await this.getAllKeys();
  },
};
</script>

<style>
.slot-add-container {
  border: 1px solid #4caf50;
  width: 100%;
  border-radius: 5px;
}

.slot-reset-container {
  width: 100%;
}
</style>