export const templateDashboardMixin = {
  data() {
    return {
      formWidth: "700px",
    };
  },

  methods: {
    formContainerStyle(type) {
      switch (type) {
        case 0:
          break;
        case 1:
          return `width: calc(100% - ${this.formWidth});`;
        case 2:
          break;
        default:
          console.error(
            "Not a valid dashboard type please select a valid dashboard type"
          );
      }

      return "";
    },

    dashboardContainerType(type) {
      switch (type) {
        case 0:
          break;
        case 1:
          return `width: ${this.formWidth} !important;min-width: ${this.formWidth} !important;max-width: 100vw;height:100%;background: ${this.soTheme.background};`;
        case 2:
          break;
        default:
          console.error(
            "Not a valid dashboard type please select a valid dashboard type"
          );
      }

      return "";
    },
  },
};
