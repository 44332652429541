<template>
  <div>
    <h3>{{ $t("module.meta.fields.default_timespan") }}</h3>
    <div>
      <v-btn
        v-for="t in timespans"
        :key="t.value"
        :color="timespan === t.value ? 'primary' : ''"
        :outlined="timespan === t.value"
        small
        @click="() => (timespan = t.value)"
        class="ma-2"
      >
        <v-icon small>mdi-calendar-range</v-icon>
        <span class="pa-2">{{ t.name }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "StandardTimeSpanSelector",

  props: {
    value: {
      default: String,
      require: true,
    },
  },

  model: {
    prop: "value",
  },

  data() {
    return {
      timespans: [
        { name: this.$t("common.dateRange.0"), value: "hour" },
        { name: this.$t("common.dateRange.1"), value: "5_hour" },
        { name: this.$t("common.dateRange.2"), value: "24_hour" },
        { name: this.$t("common.dateRange.3"), value: "7_days" },
        { name: this.$t("common.dateRange.4"), value: "30_days" },
      ],
    };
  },

  computed: {
    timespan: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>