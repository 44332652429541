<template>
  <v-card flat>
    <v-card-title>{{
      moduleTypes.find((d) => d.value === widgetType).name
    }}</v-card-title>

    <v-card-text>
      <v-text-field
        :label="$t('module.fields.title')"
        prepend-icon="mdi-domain"
        v-model="widget.title"
      />
    </v-card-text>

    <v-textarea
      v-if="isType(MType.INFOCARD, widgetType)"
      :label="$t('module.fields.title')"
      prepend-icon="mdi-domain"
      v-model="widget.title"
    />

    <text-editor-module
      v-if="isType(MType.TEXT, widgetType)"
      v-on:update-html="(val) => (widget.content = val)"
      :content="widget.content"
    />

    <div v-if="isType([MType.GAUGE, MType.FILLINDICATOR], widgetType)">
      <v-row
        style="max-width: 100% !important"
        class="ma-0"
        align-center
        justify-center
      >
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('module.fields.min')"
            :value="getMeta(metaKeys.MIN)?.value"
            @change="(val) => addMeta(metaKeys.MIN, val)"
            single-line
            prepend-icon="mdi-priority-low"
          />
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-text-field
            :label="$t('module.fields.max')"
            :value="getMeta(metaKeys.MAX)?.value"
            @change="(val) => addMeta(metaKeys.MAX, val)"
            single-line
            prepend-icon="mdi-priority-high"
          />
        </v-col>
      </v-row>
    </div>

    <v-card-text v-if="isType(MType.IFRAME, widgetType)">
      <v-text-field
        :label="$t('module.fields.url')"
        prepend-icon="mdi-url"
        v-model="widget.content"
      />
    </v-card-text>

    <v-card-text
      class="text-center"
      v-if="isType([MType.IMAGE, MType.IMAGEMAP], widgetType)"
    >
      <v-file-input
        center
        :label="$t('module.fields.image')"
        v-model="widget.image"
      />
    </v-card-text>

    <weather-form v-if="isType([MType.WEATHER], widgetType)" v-model="widget" />

    <v-card-text>
      <widget-meta-form v-model="widget" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import CsharpEnum from "../../_helpers/CsharpEnum";
import ModuleTypeHelper from "../../_helpers/ModuleTypeHelper";
import WidgetMixin from "../../_helpers/widgetMixin";

import TextEditorModule from "../common/TextEditorModule.vue";
import WidgetMetaForm from "./customForm/WidgetMetaForm.vue";
import WeatherForm from "./customForm/WeatherForm.vue";

export default {
  name: "WidgetAttributeForm",

  mixins: [WidgetMixin],
  components: { TextEditorModule, WidgetMetaForm, WeatherForm },

  props: {
    propWidget: {
      default: () => {
        return {
          widgetType: CsharpEnum.ModuleType.MULTICHART,
          title: "",
          content: "",
          slots: [],
          meta: [],
        };
      },
    },
  },

  model: {
    prop: "propWidget",
  },

  data() {
    return {};
  },

  computed: {
    ...mapState("slots", { storeSlots: "slots" }),

    MType() {
      return CsharpEnum.ModuleType;
    },

    moduleTypes() {
      return ModuleTypeHelper.types;
    },

    widgetType() {
      return this.widget?.widgetType ?? CsharpEnum.ModuleType.MULTICHART;
    },

    meta: {
      get() {
        return this.widget.meta;
      },

      set(val) {
        this.widget.meta = val;
      },
    },

    slots: {
      get() {
        return this.widget.slots;
      },

      set(val) {
        this.widget.slots = val;
      },
    },

    widget: {
      get() {
        return this.propWidget;
      },

      set(val) {
        this.$emit("input", val);
      },
    },

    fullSlots() {
      return this.storeSlots.filter((d) => this.slots.contains(d.id));
    },
  },

  methods: {},
};
</script>