<template>
  <div class="ma-2">
    <div class="d-flex justify-start align-center">
      <h4>{{ $t("widget.weather.pickTitle") }}</h4>
      <pick-location :resource="latLng" @update-pos="(v) => (latLng = v)" />
    </div>

    <h4>
      Latitude:
      <b>
        {{
          latLng?.latitude !== undefined ? latLng.latitude.toFixed(4) : "N/A"
        }}
      </b>
    </h4>
    <h4>
      Longitude:
      <b>
        {{
          latLng?.longitude !== undefined ? latLng.longitude.toFixed(4) : "N/A"
        }}
      </b>
    </h4>
  </div>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";
import PickLocation from "../../common/PickLocation.vue";
export default {
  name: "WeatherForm",

  components: { PickLocation },

  props: {
    mdlWidget: {
      default: () => {},
      require: true,
    },
  },

  model: { prop: "mdlWidget" },

  mixins: [widgetMixin],

  data() {
    return { ll: undefined };
  },

  computed: {
    latLng: {
      get() {
        try {
          return JSON.parse(this.mdlWidget?.content);
        } catch {
          return undefined;
        }
      },

      set(val) {
        let w = {
          ...this.mdlWidget,
          content: JSON.stringify(val),
        };

        this.$emit("input", w);
      },
    },

    // widget: {
    //   get() {
    //     return this.mdlWidget;
    //   },

    //   set(val) {
    //     this.$emit("input", val);
    //   },
    // },
  },
};
</script>