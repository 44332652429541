<template>
  <div>
    <h3>{{ $t(`module.meta.fields.${mKey}`) }}</h3>

    <v-autocomplete
      class="my-2"
      autocomplete="off"
      :label="$t('module.meta.fields.font_style_large.size')"
      :items="fontSizes"
      prepend-inner-icon="mdi-format-color-text"
      clearable
      outlined
      dense
      hide-details
      :value="getMeta(mKey, slotId)?.value"
      @change="(val) => addMeta(mKey, val, slotId)"
    >
      <template v-slot:item="item">
        <span :style="'font-size: ' + item.item + 'rem'"> Text </span>
      </template>
    </v-autocomplete>

    <v-autocomplete
      autocomplete="off"
      :label="$t('module.meta.fields.font_style_large.weight')"
      :items="fontWeights"
      item-text="name"
      item-value="value"
      prepend-inner-icon="mdi-format-color-text"
      clearable
      outlined
      dense
      hide-details
      :value="getMeta(mKey, slotId)?.content"
      @change="(val) => addMeta(mKey, val, slotId, 'content')"
    >
      <template v-slot:item="data">
        <span :class="data.item.value">
          {{ data.item.name }}
        </span>
      </template>
    </v-autocomplete>
    <v-divider class="mt-2" />
  </div>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";

export default {
  name: "FontForm",

  props: {
    slotId: { default: undefined },

    mKey: {
      type: String,
      default: "",
      required: true,
    },

    mdlWidget: {
      default: () => {},
      required: true,
    },
  },

  model: { prop: "mdlWidget" },

  mixins: [widgetMixin],

  computed: {
    widget: {
      get() {
        return this.mdlWidget;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>