<template>
  <div class="py-2">
    <h3>{{ $t("module.meta.keys.value_match") }}</h3>
    <div class="mb-2">
      <v-chip
        color="primary"
        close
        v-for="(m, i) in getMetas(metaKeys.VALUEMATCH)"
        :key="i"
        @click:close="() => removeMeta(m.templateWidgetMetaId)"
        class="mx-1"
        >{{ m.value }} = {{ m.content }}
      </v-chip>
    </div>

    <v-row no-gutters class="d-flex justify-center align-center">
      <v-text-field
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-alpha-v"
        :label="labelForMeta(metaKeys.VALUEMATCH, 0)"
        v-model="val"
      />
      <v-text-field
        outlined
        dense
        hide-details
        prepend-inner-icon="mdi-alpha-n"
        :label="labelForMeta(metaKeys.VALUEMATCH, 1)"
        v-model="cont"
      />

      <v-btn class="ml-3" text outlined color="primary" @click="add">
        {{ $t("common.add") }}</v-btn
      >
    </v-row>
  </div>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";

export default {
  name: "ValueMatchForm",

  props: {
    mdlWidget: {
      default: () => {},
      required: true,
    },
  },

  model: { prop: "mdlWidget" },

  mixins: [widgetMixin],

  data() {
    return { val: "", cont: "" };
  },

  methods: {
    add() {
      let newMeta = {
        templateWidgetMetaId: crypto.randomUUID(),
        key: this.metaKeys.VALUEMATCH,
        value: this.val,
        content: this.cont,
        slotId: undefined,
      };

      this.meta.push(newMeta);
    },
  },

  computed: {
    widget: {
      get() {
        return this.mdlWidget;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
