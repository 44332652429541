<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1">{{
        $t("widget.form.step1")
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="step > 2" step="2">{{
        $t("widget.form.step2")
      }}</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="step > 3" step="3">{{
        $t("widget.form.step3")
      }}</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items v-if="widget != undefined">
      <v-stepper-content step="1">
        <div style="max-width: 100% !important">
          <widget-type-selector v-model="widget.widgetType" />
        </div>

        <div class="d-flex jusitfy-space-between align-start">
          <v-spacer />
          <v-btn class="mt-2" text color="primary" @click="advance">
            {{ $t("common.next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="2">
        <slot-selector v-model="widget.slots" />

        <div class="d-flex jusitfy-space-between align-start">
          <v-spacer />
          <v-btn class="mt-2" text @click="back">
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn class="mt-2" text color="primary" @click="advance">
            {{ $t("common.next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="3">
        <slot-reindex-form
          v-model="widget"
          v-if="
            isType(
              [wTypes.GAUGE, wTypes.SINGLE, wTypes.FILLINDICATOR],
              widget.widgetType
            )
          "
        />

        <widget-attribute-form v-model="widget" />

        <div class="d-flex jusitfy-space-between align-start">
          <v-spacer />
          <v-btn class="mt-2" text @click="back">
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn class="mt-2" text color="primary" @click="advance">
            {{ $t("common.next") }}
          </v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="99">
        <div class="d-flex jusitfy-space-between align-start">
          <v-spacer />
          <v-btn class="mt-2" text @click="back">
            {{ $t("common.previousStep") }}
          </v-btn>

          <v-btn class="mt-2" text color="primary" @click="finish">
            {{ $t("common.save") }}
          </v-btn>
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>


<script>
import WidgetTypeSelector from "./WidgetTypeSelector.vue";
import WidgetAttributeForm from "./WidgetAttributeForm.vue";
import SlotSelector from "./SlotSelector.vue";
import { mapActions, mapGetters } from "vuex";
import ModuleTypeHelper from "../../_helpers/ModuleTypeHelper";
import SlotReindexForm from "./customForm/SlotReindexForm.vue";
import ImageRepository from "../../api/repositories/imageRepository";
import widgetMixin from "../../_helpers/widgetMixin";
import CE from "../../_helpers/CsharpEnum";

const defaultWidget = {
  widgetType: 10,
  title: "",
  content: "",
  slots: [],
  meta: [],
};

export default {
  name: "WidgetForm",

  components: {
    WidgetTypeSelector,
    SlotSelector,
    WidgetAttributeForm,
    SlotReindexForm,
  },

  mixins: [widgetMixin],

  data() {
    return {
      step: 1,
      backStep: 1,
      widget: defaultWidget,
    };
  },

  props: {
    inputWidget: {
      default: undefined,
    },
  },

  computed: {
    ...mapGetters("users", ["user"]),
    widgetTypes: () => ModuleTypeHelper.types,
    wTypes: () => CE.ModuleType,
  },

  methods: {
    ...mapActions("slots", ["resetSlots"]),

    async advance() {
      var typeIdx = this.widgetTypes.findIndex(
        (d) => d.value == this.widget.widgetType
      );
      if (typeIdx === -1) return;

      this.backStep = this.step;
      // Find next step
      var stps = this.widgetTypes[typeIdx].steps;
      stps = stps.filter((d) => d > this.step);
      if (stps.length === 0) {
        // We should finish save the wiget to the dashboard
        await this.finish();
        return;
      }

      this.step = stps[0];
    },

    back() {
      var typeIdx = this.widgetTypes.findIndex(
        (d) => d.value == this.widget.widgetType
      );
      if (typeIdx === -1) return;

      // Find next step
      var stps = this.widgetTypes[typeIdx].steps;
      stps = stps.filter((d) => d < this.step);
      if (stps.length === 0)
        // We should finish
        return;

      this.step = stps[stps.length - 1];

      if (this.step == 1) this.resetForm();
    },

    async finish() {
      // Save and reset
      this.step = 1;

      if (this.widget.templateWidgetId === undefined)
        this.widget.templateWidgetId = crypto.randomUUID();

      // Check if image should be uploaded if that is the case upload the image and store the path in the
      if (this.widget.image !== undefined) {
        // Upload image and save path
        let formData = new FormData();
        formData.append("image", this.widget.image);

        let result = await ImageRepository.postImage(this.user, formData)
          .then((d) => d.data)
          .catch((d) => {
            console.error(d);
            return undefined;
          });

        if (result !== undefined) this.widget.imagePath = result;
      }

      this.$emit("add-or-update-widget", this.widget);

      this.resetForm();
    },

    resetForm() {
      this.step = 1;
      this.backstep = 1;
      this.widget = {
        templateWidgetId: undefined,
        widgetType: 10,
        title: "",
        content: "",
        slots: [],
        meta: [],
      };
    },
  },

  created() {
    this.resetForm();
  },

  watch: {
    async inputWidget(val) {
      if (val === undefined) {
        this.widget = defaultWidget;
      } else {
        this.widget = val;

        // We advance the step here because we
        // dont want to allow the user to change
        // the dashboard type
        await this.advance();
      }
    },
  },
};
</script>