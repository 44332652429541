<template>
  <div>
    <h3>{{ $t("slot.priorityTitle") }}</h3>
    <p>{{ $t("slot.priorityDescription") }}</p>

    <draggable
      v-if="widget?.slots !== undefined"
      v-model="widget.slots"
      @change="changed"
      class="slot-indexing-container d-flex justify-start align-center flex-wrap"
    >
      <v-hover v-for="(slt, i) in widget?.slots ?? []" :key="slt.slotId">
        <template v-slot:default="{ hover }">
          <div
            :style="{
              background: soTheme.accentBackground,
            }"
            :class="`${
              hover ? 'elevation-4' : 'elevation-2'
            } rounded-lg border ma-2 pa-2`"
          >
            <h3 class="title">Priority {{ i + 1 }}</h3>
            <span class="subtitle">
              {{ `Node Slot ${slt.index} - ${slt.key}` }}
            </span>
          </div>
        </template>
      </v-hover>
    </draggable>
  </div>
</template>

<script>
import widgetMixin from "../../../_helpers/widgetMixin";
import draggable from "vuedraggable";

export default {
  name: "SlotReIndexForm",

  mixins: [widgetMixin],
  components: { draggable },

  props: {
    mdlWidget: {
      default: () => {},
    },
  },

  model: { prop: "mdlWidget" },

  methods: {
    changed() {
      for (var i = 0; i < this.widget?.slots?.length ?? 0; i++) {
        this.widget.slots[i].priority = i;
      }
    },
  },

  computed: {
    widget: {
      get() {
        return this.mdlWidget;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  created() {},
};
</script>

<style lang="scss">
.slot-indexing-slot {
  border-radius: 5px;
}
</style>
