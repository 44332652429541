import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/image";

export default {
  postImage(user, image) {
    let config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}`, image, config);
  },
};
